import Vue from "vue";
import App from "./App.vue";

import "@/scss/main.scss";

import uikit from "uikit/dist/js/uikit.min.js";
window.UIkit = uikit;

Vue.config.productionTip = false;

new Vue({
	render: function(h) {
		return h(App);
	},
}).$mount("#app");
