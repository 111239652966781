<template>
	<div>
		<div class="uk-container uk-container-large">
			<div class="uk-grid uk-visible@s">
				<div class="uk-width-1-1">
					<img src="@/assets/images/NIX18_landingspagina posters.png" class="uk-width-1-1" />
				</div>
			</div>
			<div class="uk-grid uk-hidden@s uk-margin-medium-bottom">
				<div class="uk-width-1-1 uk-margin-large-bottom">
					<img src="@/assets/images/NIX18_landingspagina posters_L.png" class="uk-width-1-1" />
				</div>
				<div class="uk-width-1-1">
					<img src="@/assets/images/NIX18_landingspagina posters_R.png" class="uk-width-1-1" />
				</div>
			</div>
		</div>
		<div class="uk-container uk-container-large">
			<div class="uk-grid">
				<div class="home-col-left uk-visible@m">
					<p class="uk-text-large uk-text-bold">
						Schrijf hier de inlog code die u heeft gekregen in de brief van GGD Gooi en Vechtstreek. Na het invullen van de inlogcode verschijnt een scherm waarop uw persoonlijke poster is te zien. Deze kunt u zowel digitaal als in druk bestellen.
					</p>
				</div>
				<div class="uk-width-1-1 uk-margin-medium-top uk-hidden@m">
					<p class="uk-text-large uk-text-bold">
						Schrijf hier de inlog code die u heeft gekregen in de brief van GGD Gooi en Vechtstreek. Na het invullen van de inlogcode verschijnt een scherm waarop uw persoonlijke poster is te zien. Deze kunt u zowel digitaal als in druk bestellen.
					</p>
				</div>
				<div class="home-col-center uk-visible@m"></div>
				<div class="uk-width-expand uk-margin-large-top">
					<div class="uk-margin-medium-bottom">
						<div>
							<input type="text" class="uk-input nix-input" placeholder="Vul uw code in" maxlength="6" v-model="code" />
						</div>
					</div>
					<div class="uk-margin-medium-bottom">
						<button class="uk-button uk-button-primary uk-button-large nix-button" @click="checkCode">
							Log in
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "Home",
	data() {
		return {
			code: "",
		};
	},
	methods: {
		checkCode() {
			axios({
				method: "GET",
				url: "https://nix18.clickandorder.nl/ajax/codecheck/",
				params: {
					force: 1,
					code: this.code,
				},
				data: {},
				headers: {},
			}).then((response) => {
				console.log(response);
				if (response.status == 200) {
					if (response.data.status == "success") {
						location.href = "https://nix18.clickandorderstore.nl/mailing/" + response.data.hash;
					} else {
						window.UIkit.notification({
							message: response.data.message,
							status: "danger",
							pos: "top-center",
							timeout: 5000,
						});
					}
				}
			});
		},
	},
	created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
